<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useLocalization } from '/~/composables/localization'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = defineProps<{
  onConfirm?: () => Promise<void>
  onCancel?: () => void
}>()

const { translate } = useLocalization()

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: async () => {
      await props.onConfirm?.()
    },
    onCancel: () => {
      props.onCancel?.()
    },
    hide,
  })

function hide() {
  modal.hide()
}
</script>

<template>
  <base-mdl width="xse" border-radius="xl" :closable="!loading">
    <template #header>
      <div class="relative py-5 text-center">
        <span class="text-primary">
          <base-icon
            svg="heroicons/solid/information-circle"
            :size="64"
            class="mx-auto"
          />
        </span>
        <span class="absolute right-0 top-0 mr-6 mt-6">
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              component1: 'Pay now',
              cta: 'Close',
            }"
            icon="heroicons/solid/x-mark"
            :size="36"
            :disabled="loading"
            @click="onCancel"
          />
        </span>
        <h2 class="mt-4 text-center text-2xl text-eonx-neutral-800">Pay now</h2>
      </div>
    </template>

    <div class="mb-6">
      <p class="mb-6">
        {{ translate('directDebit.text') }} is enabled for this account.
      </p>
      <p>
        By making a payment now, you will settle your outstanding balance for
        the current statement and your automatic payments will resume as
        scheduled for your next month's statement.
      </p>
    </div>

    <template #footer>
      <div>
        <div class="mt-6 flex gap-4">
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              component1: 'Pay now',
              cta: 'Cancel',
            }"
            class="flex-auto"
            look="outlined-color"
            size="lge"
            :disabled="loading"
            :loading="cancelling"
            @click="onCancel"
          >
            Cancel
          </base-button>
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              component1: 'Pay now',
              cta: 'Pay now',
            }"
            class="flex-auto"
            size="lge"
            :disabled="loading"
            :loading="confirming"
            @click="onConfirm"
          >
            Pay now
          </base-button>
        </div>
      </div>
    </template>
  </base-mdl>
</template>
